import React, { useState } from "react";
import { checkEmailAddress } from "../utils";
import axios from "axios";

const formAPIURL = `${process.env.GATSBY_AI_SOLUTIONS_PAGE_FORM_URL}`;

export const CollectEmailAddressForm = ({ demoFor, redirectTo }) => {
    const [emailAddress, setEmailAddress] = useState("");
    const [error, setError] = useState();
    const isAlreadyCollected = !!localStorage.getItem("email-collected");
    const saveEmail = async () => {
        if (emailAddress && checkEmailAddress(emailAddress)) {
            try {
                await axios.post(
                    formAPIURL,
                    {
                        emailAddress,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    }
                );
                localStorage.setItem("email-collected", true);
                setEmailAddress("");
                setError(null);
            } catch (e) {
                setError("Something went wrong. Please try again.");
            }
        } else {
            setError("Please enter a valid email address");
        }
    };
    return (
        <>
            {isAlreadyCollected === true ? (
                <>
                    <div className="text-h3">You can now access the demo</div>
                    <div>
                        🔓 Passkey is{" "}
                        {demoFor === "SEA"
                            ? process.env.GATSBY_SEA_PASS_KEY
                            : process.env.GATSBY_CHITRA_PASS_KEY}{" "}
                        <a
                            className="sdn-link"
                            style={{ fontSize: 17 }}
                            href={redirectTo}
                            target="no_refer"
                        >
                            click here to access the demo.
                        </a>
                    </div>
                </>
            ) : (
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        saveEmail();
                    }}
                >
                    <div className="text-h3 gap-1x">Please fill the form to access the demo.</div>
                    <div className="input-row gap-1x">
                        <div className="mat-group">
                            <input
                                type="text"
                                name="email-address"
                                required
                                value={emailAddress}
                                autoComplete="off"
                                onChange={(e) => setEmailAddress(e.target.value)}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="email address">Email Address*</label>
                            <div className="error-resp">{error}</div>
                        </div>
                    </div>
                    <button className="sdn-cta-btn next" type="submit">
                        <span>Submit</span>
                    </button>
                </form>
            )}
        </>
    );
};
