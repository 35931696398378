import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const GenericModal = ({ children, open, onClose }) => {
    return (
        <>
            {open && (
                <div className="sedin-modal">
                    <div className="sedin-modal-content">
                        <div className="sedin-modal-header">
                            <div onClick={onClose}>
                                <StaticImage
                                    src="../images/close.svg"
                                    class="close-icon"
                                    alt="close-icon"
                                />
                            </div>
                        </div>
                        <div className="sedin-modal-body">{children}</div>
                    </div>
                </div>
            )}
        </>
    );
};
